// Global packages and components
import { clsx } from 'clsx'
import { ChevronRightIcon } from '@heroicons/react/24/outline'

// Types
import type { SanityCustomImage } from '~/types'

interface NavLinkInnerProps {
  icon?: SanityCustomImage
  title: string
  titleBold?: boolean
  titleHighlight?: boolean
  hasArrow?: boolean
  hasIcon?: boolean
}

// Main export
const LinkInner = ({
  icon,
  title,
  titleBold,
  titleHighlight,
  hasArrow,
  hasIcon,
}: NavLinkInnerProps) => (
  <div className="flex w-full items-center justify-between">
    <div className="flex w-full items-center">
      {hasIcon && icon?.asset?.url && (
        <img
          src={icon.asset.url}
          alt={icon?.alt}
          className="mr-2 max-w-10"
        />
      )}
      <div
        className={clsx({
          'font-bold': titleBold,
          'text-red-600': titleHighlight,
        })}
      >
        {title}
      </div>
    </div>
    {hasArrow && <ChevronRightIcon className="ml-3 size-4" />}
  </div>
)

export default LinkInner
