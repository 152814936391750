// Global packages and components
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

// Types
import type { ReactNode } from 'react'

interface Props {
  message?: string
  children?: ReactNode
}

// Main export
const ErrorResponse = ({ message, children }: Props = {}) => (
  <div className="mb-10 flex w-full flex-col items-center rounded-2xl bg-gray-100 px-8 py-14 text-center">
    <ExclamationTriangleIcon className="mb-4 size-20 text-red-600" />
    <p className="text-2xl font-bold">
      {message ? message : 'It looks like something went wrong!'}
    </p>
    {children}
  </div>
)

export default ErrorResponse
